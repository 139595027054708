var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticStyle: { "background-color": "#93bb61" },
      attrs: { "fill-height": "", "fill-width": "" },
    },
    [_c("h1", [_vm._v(_vm._s(_vm.$t("Account Subscription")))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }