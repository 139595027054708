var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "account-profile pa-5",
      attrs: { vertical: "", gap: "1rem" },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "profile-data",
          attrs: { vertical: "", justify: "flex-start" },
        },
        [
          _c(
            "cs-flex",
            { attrs: { vertical: "" } },
            [
              _c(
                "cs-flex",
                {
                  staticClass: "py-5 px-11",
                  attrs: { "fill-width": "", justify: "stretch" },
                },
                [
                  _c("span", { staticClass: "profile-title" }, [
                    _vm._v(_vm._s(_vm.$t("Profile"))),
                  ]),
                  _c("cs-tooltip-avatar", {
                    staticClass: "cs-tooltip-avatar ml-auto",
                    attrs: {
                      email: _vm.currentUser.email,
                      tooltipText: _vm.$t(
                        "This avatar is visible to others when sharing"
                      ),
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c("span", { staticClass: "text-bold px-11 py-7" }, [
                _vm._v(_vm._s(_vm.$t("Personal"))),
              ]),
              _c(
                "cs-grid",
                {
                  staticClass: "pt-2 pb-6 px-11",
                  attrs: {
                    "fill-width": "",
                    "template-columns": "repeat(2, 1fr)",
                    "auto-rows": "",
                    gap: "2rem 0rem",
                  },
                },
                [
                  _c("span", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Name")) + " "),
                  ]),
                  _c("span", { staticClass: "right-col text-small ml-auto" }, [
                    _vm._v(" " + _vm._s(_vm.displayName) + " "),
                  ]),
                  _c("span", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Email")) + " "),
                  ]),
                  _c("span", { staticClass: "right-col text-small ml-auto" }, [
                    _vm._v(" " + _vm._s(_vm.currentUser.email) + " "),
                  ]),
                  _c("span", { staticClass: "text-bold text-small mr-auto" }, [
                    _vm._v(" " + _vm._s(_vm.$t("Account type")) + " "),
                  ]),
                  _c(
                    "span",
                    { staticClass: "text-blue right-col text-small ml-auto" },
                    [_vm._v(" Consteel ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "cs-flex",
            {
              staticClass: "text-small py-5 px-11",
              attrs: { vertical: "", gap: "1rem" },
            },
            [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      _vm.showLegalDialog = true
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Privacy policy")))]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.currentUserDoc
        ? _c(
            "cs-flex",
            { staticClass: "subscription-data", attrs: { vertical: "" } },
            [
              _c("span", { staticClass: "text-bold py-5 px-11" }, [
                _vm._v(_vm._s(_vm.$t("Current subscription"))),
              ]),
              _c("v-divider"),
              _c(
                "cs-flex",
                { staticClass: "py-5 px-11", attrs: { gap: "10rem" } },
                [
                  _c(
                    "cs-flex",
                    {
                      attrs: {
                        justify: "flex-start",
                        vertical: "",
                        "fill-height": "",
                      },
                    },
                    [
                      _c("span", { staticClass: "text-bold pb-9" }, [
                        _vm._v(_vm._s(_vm.subscriptionPlan)),
                      ]),
                      _c(
                        "cs-flex",
                        {
                          staticClass: "pb-4",
                          attrs: { "fill-width": "", justify: "flex-start" },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-6" }, [
                            _vm._v("mdi-check"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("View own models"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "cs-flex",
                        {
                          staticClass: "pb-4",
                          attrs: { "fill-width": "", justify: "flex-start" },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-6" }, [
                            _vm._v("mdi-check"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("Folder management"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "cs-flex",
                        {
                          staticClass: "pb-4",
                          attrs: { "fill-width": "", justify: "flex-start" },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-6" }, [
                            _vm._v("mdi-check"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("View shared models"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "cs-flex",
                        {
                          staticClass: "pb-4",
                          attrs: { "fill-width": "", justify: "flex-start" },
                        },
                        [
                          _vm.currentUserDoc.subscriptionType === "basic"
                            ? _c("v-icon", { staticClass: "mr-6" }, [
                                _vm._v(" mdi-close "),
                              ])
                            : _c("v-icon", { staticClass: "mr-6" }, [
                                _vm._v(" mdi-check "),
                              ]),
                          _c("span", [_vm._v(_vm._s(_vm.$t("Share models")))]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "cs-flex",
                    {
                      staticClass: "right-col",
                      attrs: {
                        justify: "flex-start",
                        vertical: "",
                        "fill-height": "",
                      },
                    },
                    [
                      _c("span", { staticClass: "text-bold pb-9" }, [
                        _vm._v(_vm._s(_vm.$t("Storage"))),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.$t("Monthly reneval")))]),
                      _c(
                        "v-icon",
                        {
                          staticClass: "mt-7 pb-5 grey--text text--lighten-3",
                          attrs: { "x-large": "" },
                        },
                        [_vm._v("mdi-cloud")]
                      ),
                      _c("cs-usage-progress", {
                        attrs: {
                          title: _vm.$t("Bandwidth"),
                          unit: "MB",
                          max: _vm.bandwidthLimit,
                          current: _vm.bandwidthUsage,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("legal-dialog", {
        model: {
          value: _vm.showLegalDialog,
          callback: function ($$v) {
            _vm.showLegalDialog = $$v
          },
          expression: "showLegalDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }