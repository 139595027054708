
import LegalDialog from '@/components/Landing/LegalDialog.vue'
import { CsFlex, CsGrid, CsTooltipAvatar, CsUsageProgress } from '@consteel/csuetify'
import { getApp } from '@firebase/app'
import { Unsubscribe, User as FBUser } from '@firebase/auth'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import Vue from 'vue'
import { ServiceLimitaiton, ServiceUsage, User } from '@/modules/userService'
import { authStore } from '@/modules/auth/auth.store'

export default Vue.extend({
  name: 'AccountProfile',
  components: {
    CsFlex,
    CsGrid,
    CsTooltipAvatar,
    LegalDialog,
    CsUsageProgress,
  },
  data() {
    return {
      firebaseUnsubs: [] as Unsubscribe[],
      showLegalDialog: false,
      currentUserDoc: undefined as User | undefined,
      bandwidthLimit: 50,
      bandwidthUsage: 0,
    }
  },
  computed: {
    currentUser(): FBUser | null {
      return authStore.currentSteelspaceUser
    },
    displayName(): string | undefined {
      if (!this.currentUser?.displayName) {
        return undefined
      }
      return this.currentUser?.displayName
    },
    subscriptionPlan(): string {
      const plan: string | undefined = this.currentUserDoc?.subscriptionType
      return `${plan?.charAt(0).toUpperCase()}${plan?.slice(1)} ${this.$t('plan')}`
    },
  },
  async created() {
    this.currentUserDoc = (
      await getDoc(doc(collection(getFirestore(getApp()), 'users'), this.currentUser?.uid))
    ).data() as User

    const serviceLimitations = await getDocs(
      collection(
        getFirestore(getApp()),
        `users/${authStore.currentSteelspaceUser?.uid}/serviceLimitations`
      )
    )

    serviceLimitations.forEach((doc) => {
      const data = doc.data() as ServiceLimitaiton

      if (data.serviceName === 'steelspace_bandwidth') {
        this.bandwidthLimit = Math.floor(data.limit / 1000)
      }
    })

    const unsubFromBandwidthUsage = onSnapshot(
      query(
        collection(
          getFirestore(getApp()),
          `users/${authStore.currentSteelspaceUser?.uid}/serviceUsages`
        ),
        where('month', '==', new Date().getMonth() + 1),
        where('year', '==', new Date().getFullYear())
      ),
      (docs) =>
        docs.forEach((doc) => {
          const data = doc.data() as ServiceUsage
          if (data.serviceName === 'steelspace_bandwidth') {
            this.bandwidthUsage = Math.floor(data.usage / 1000)
          }
        })
    )

    this.firebaseUnsubs.push(unsubFromBandwidthUsage)
  },
  beforeDestroy() {
    this.firebaseUnsubs.forEach((unsub) => unsub())
  },
})
