
import { CsFlex } from '@consteel/csuetify'
import Vue from 'vue'

export default Vue.extend({
  name: 'AccountSubscription',
  components: {
    CsFlex,
  },
})
